import React from 'react';
import { graphql } from 'gatsby';

import Container from 'components/organisms/Container';
import Banner from 'components/molecules/Banner';
import PersonalCard from 'components/molecules/PersonalCard';
import { PersonalCardProps } from 'components/molecules/PersonalCard/models.d';
import IntroSection from 'components/atoms/IntroSection';
import Seo from 'components/atoms/Seo';

const Kadra = ({
  data: {
    contentfulPersonnel: {
      slug,
      introText,
      readMoreButtonLabel,
      banner: { bannerSlide },
      trainers,
    },
  },
}) => (
  <div className="personel">
    <Seo title="Kadra" />
    <Banner slides={bannerSlide} />
    <Container>
      <IntroSection text={introText} justify="center" />
      <div className="personel__trainers">
        {trainers.map((trainer: PersonalCardProps, i: number) => (
          <PersonalCard
            key={trainer.name + trainer.surname}
            {...trainer}
            baseSlug={slug}
            idx={i}
            readMoreButtonLabel={readMoreButtonLabel}
          />
        ))}
      </div>
    </Container>
  </div>
);

export const query = graphql`
  {
    contentfulPersonnel {
      slug
      introText
      readMoreButtonLabel
      banner {
        bannerSlide {
          title
          image {
            image {
              gatsbyImageData(formats: WEBP)
            }
            altText
          }
        }
      }
      trainers {
        name
        surname
        about {
          about
        }
        entitlements
        experience
        successes
        photo {
          altText
          image {
            gatsbyImageData(formats: WEBP)
          }
        }
      }
    }
  }
`;

export default Kadra;
