import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import _ from 'lodash';

import Link from 'components/atoms/Link';

import { PersonalCardProps } from './models.d';

const PersonalCard: FC<PersonalCardProps> = ({
  name,
  surname,
  photo,
  baseSlug,
  about,
  entitlements,
  experience,
  successes,
  idx,
  readMoreButtonLabel,
}) => (
  <div className="personal-card" style={{ animationDelay: `${idx && idx * 0.3}s` }}>
    <Link
      to={`${baseSlug}/${_.snakeCase(name + surname)}`}
      state={{ name, surname, photo, about, entitlements, experience, successes }}
      aria={`${name} ${surname}`}
    >
      <GatsbyImage
        image={photo.image.gatsbyImageData}
        alt={photo.altText}
        className="personal-card__photo"
      />
      <div className="personal-card__personals">
        <div className="personal-card__name">{name}</div>
        <div className="personal-card__surname">{surname}</div>
        <p className="personal-card__read-more">{readMoreButtonLabel}</p>
      </div>
    </Link>
  </div>
);

export default PersonalCard;
